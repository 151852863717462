import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE, SupportedLocale } from '../domain/constants'
import { ShopifyProductId, ShopifyProductVariantId } from '../shopify/types'
import { validate } from '../utils'
import { GLOBAL_COLLECTIONS_FRAGMENT } from './fragments/common/dictionary/globalCollections.fragment'
import { GLOBAL_PRODUCTS_FRAGMENT } from './fragments/common/dictionary/globalProducts.fragment'
import {
  GLOBAL_SEARCH_SETTINGS_FRAGMENT,
  GLOBAL_SEARCH_SETTINGS_TERMS_FRAGMENT,
  SearchSettingsSchema,
  SearchSettingsTermsSchema,
} from './fragments/common/dictionary/globalSearch.fragment'
import { GLOBAL_TERMS_FRAGMENT } from './fragments/common/dictionary/globalTerms.fragment'
import { GET_IMAGE_FRAGMENT, Image, ImageSchema } from './fragments/common/getImage.fragment'
import { PRODUCT_IMAGE_TYPE_FRAGMENT } from './fragments/common/getImageType.fragment'
import { SECTION_TYPES_FRAGMENT, SectionType } from './fragments/common/sectionTypes.fragment'
import { ANNOUNCEMENT_BAR_FRAGMENT, AnnouncementBarSchema } from './fragments/components/announcementBar.fragment'
import { COLLECTION_BREADCRUMB_FRAGMENT, CollectionBreadcrumb } from './fragments/components/breadcrumb.fragment'
import { CARE_INSTRUCTION_SET_FRAGMENT, CareInstructionSet } from './fragments/components/careInstructions.fragment'
import { Color, COLOR_FRAGMENT } from './fragments/components/color.fragment'
import { Emissions, EMISSIONS_FRAGMENT } from './fragments/components/emissions.fragment'
import { FOOTER_MENU_ITEM_FRAGMENT, FooterMenuItemSchema } from './fragments/components/footerMenuItem.fragment'
import { HOTSPOT_IMAGE_FRAGMENT, HotspotImageSchema } from './fragments/components/hotspotImage.fragment'
import { IMAGE_TEXT_LIST_ITEM_FRAGMENT, ImageTextListItem } from './fragments/components/imageTextListItem.fragment'
import { LINK_FRAGMENT, LinkSchema } from './fragments/components/link.fragment'
import { MAIN_MENU_ITEM_FRAGMENT, MainMenuItemSchema } from './fragments/components/mainMenuItem.fragment'
import { Media, MEDIA_FRAGMENT, MediaSchema } from './fragments/components/media.fragment'
import { MENU_CARD_FRAGMENT, MenuCardSchema } from './fragments/components/menuCard.fragment'
import { MODEL_BIO_FRAGMENT, ModelBio } from './fragments/components/modelBio.fragment'
import { PRODUCT_ANNOUNCEMENT_BAR_FRAGMENT, ProductAnnouncementBar, ProductAnnouncementBarSchema } from './fragments/components/productAnnouncementBar.fragment'
import { PRODUCT_CARD_PRODUCT_FRAGMENT, ProductCardProduct, ProductCardProductSchema } from './fragments/components/productCardProduct.fragment'
import { PRODUCT_COLOR_FRAGMENT, ProductColor } from './fragments/components/productColor'
import { PRODUCT_SIZE_INFO_NOTE_FRAGMENT, ProductSizeInfoNote } from './fragments/components/productSizeInfoNote.fragment'
import { RICH_TEXT_FRAGMENT, RichText, RichTextSchema } from './fragments/components/richText.fragment'
import { Seo, SEO_FRAGMENT, SeoSchema } from './fragments/components/seo.fragment'
import { SIZE_CHARTS_FRAGMENT, SizeChart } from './fragments/components/sizeChart.fragment'
import { SUSTAINABILITY_INFORMATION_FRAGMENT, SustainabilityInformation } from './fragments/components/sustainabilityInformation.fragment'
import { ACCOUNT_FRAGMENT, AccountData } from './fragments/globals/account.fragment'
import { Cart, CART_FRAGMENT, CartSchema } from './fragments/globals/cart.fragment'
import { COOKIE_BANNER_FRAGMENT, CookieBanner, CookieBannerSchema } from './fragments/globals/cookieBanner.fragment'
import { CUSTOMER_SERVICE_FRAGMENT, CustomerServiceSchema } from './fragments/globals/customerService.fragment'
import { SERVICE_USPS_SET_FRAGMENT, ServiceUspSet, ServiceUspsSchema } from './fragments/globals/serviceUspsSet.fragment'
import { STYLES_ORDER_FRAGMENT, StylesOrder, StylesOrderSchema } from './fragments/globals/stylesOrder.fragment'
import { PAGE_ARTICLE_FRAGMENT } from './fragments/pages/pageArticle.fragment'
import { Section, SECTIONS_FRAGMENT } from './fragments/pages/sections.fragment'
import { GET_SANITY_ACTIVE_COLLECTION_SEASON_FRAGMENT } from './fragments/retrieval/data'
import { CollectionSeason, ProductType, Slug, SlugSchema } from './types'
import { coalesceFilter, coalesceLocalizedValue, coalesceQuery, forceFallbackForAll, requireAllToBeTrue, sanityFetch, toSentenceCase } from './utils'

export type GetAccountDataResponse = AccountData

export async function getAccountData(locale = DEFAULT_LOCALE, draftMode = false): Promise<GetAccountDataResponse> {
  const query = defineQuery(`
    *[_type == "account"][0] {
      ${ACCOUNT_FRAGMENT(locale)}
    }
  `)

  return await sanityFetch({ query, draftMode })
}

export const AccountPageSettingsSchema = z.object({
  accountNavigation: z.array(LinkSchema),
})

export type GetAccountPageSettingsResponse = z.infer<typeof AccountPageSettingsSchema>

export async function getAccountPageSettings(locale = DEFAULT_LOCALE, draftMode = false): Promise<GetAccountPageSettingsResponse> {
  const query = defineQuery(`
    *[_type == "accountSettings"][0] {
      accountNavigation[@->page->showInLocales[$locale] != false]-> {
        ${LINK_FRAGMENT(locale)}
      }
    }
  `)

  const params = {
    locale,
  }

  return validate(AccountPageSettingsSchema, await sanityFetch({ query, draftMode, params }), {
    errorMessage: 'Failed to validate the account page settings',
    enabled: !draftMode,
  })
}

export type GetDefaultAnnouncementBarResponse = z.infer<typeof AnnouncementBarSchema>

export async function getDefaultAnnouncementBar(locale = DEFAULT_LOCALE, draftMode = false): Promise<GetDefaultAnnouncementBarResponse> {
  const query = defineQuery(`
    *[_type == "globalAnnouncementBars"][0].defaultAnnouncementBar-> {
      ${ANNOUNCEMENT_BAR_FRAGMENT(locale)}
    }
  `)

  return validate(AnnouncementBarSchema, await sanityFetch({ query, draftMode }), {
    errorMessage: 'Failed to validate the announcement bar properties',
    enabled: !draftMode,
  })
}

type GetAnnouncementBarResponse = z.infer<typeof AnnouncementBarSchema>

export const getLocalizedAnnouncementBar = async (geo: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<GetAnnouncementBarResponse> => {
  const query = defineQuery(`
    *[_type == "globalAnnouncementBars"][0][$localizedAnnouncementBar]-> {
      ${ANNOUNCEMENT_BAR_FRAGMENT(locale)}
    }
  `)

  const params = {
    localizedAnnouncementBar: `${geo}AnnouncementBar`,
  }

  return validate(AnnouncementBarSchema, await sanityFetch({ query, draftMode, params }), {
    errorMessage: 'Failed to validate the localized announcement bar properties',
    enabled: !draftMode,
  })
}

export const GetMainMenuAndMenuCardsResponseSchema = z.object({
  mainMenuItems: z.array(MainMenuItemSchema),
  topMenuCards: z.array(MenuCardSchema),
})

export async function getMainMenuAndMenuCards(locale = DEFAULT_LOCALE, draftMode = false) {
  const query = defineQuery(`
    *[_type == "navigation"][0] {
      mainMenuItems[] {
        ${MAIN_MENU_ITEM_FRAGMENT(locale)}
      },
      topMenuCards[]-> {
        ${MENU_CARD_FRAGMENT(locale)}
      }
    } {
      ...,
      mainMenuItems[_type == 'link' || length(productCategories) > 0]
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch({ query, draftMode, params })
}

export const SecondaryMenuItemSchema = z.object({
  _type: z.literal('secondaryMenuItem'),
  secondaryMenuItemTitle: z.string(),
  secondarySubmenuItems: z.array(LinkSchema),
  secondaryMenuCard: z
    .object({
      title: z.string(),
      image: ImageSchema,
      pageLink: LinkSchema,
    })
    .optional(),
})

export type SecondaryMenuItem = z.infer<typeof SecondaryMenuItemSchema>

const MinimalMainMenuItemSchema = z.object({
  _key: z.string(),
  _type: z.literal('mainMenuItem'),
  mainMenuItemLink: LinkSchema,
})

export const GetMinimalNavigationResponseSchema = z.object({
  wishlistSlug: z.object({
    slug: SlugSchema,
  }),
  mainMenuItems: z.array(z.union([MinimalMainMenuItemSchema, LinkSchema, MinimalMainMenuItemSchema.and(LinkSchema)])),
  secondaryMenuItems: z.array(z.union([SecondaryMenuItemSchema, LinkSchema, SecondaryMenuItemSchema.and(LinkSchema)])),
})

export type MinimalMainMenuItem = z.infer<typeof MinimalMainMenuItemSchema>
export type GetMinimalNavigationResponse = z.infer<typeof GetMinimalNavigationResponseSchema>

export async function getMinimalNavigation(locale = DEFAULT_LOCALE, draftMode = false): Promise<GetMinimalNavigationResponse> {
  const query = defineQuery(`
    *[_type == "navigation"][0] {
      "wishlistSlug": *[
        _type == 'pageWishlist'
      ][0] {
        ${coalesceQuery('slug', locale)},
      },
      mainMenuItems[] {
        _type == 'reference' => @-> {
          ${LINK_FRAGMENT(locale)}
        },
        _type == 'mainMenuItem' => {
          _key,
          _type,
          mainMenuItemLink-> {
            ${LINK_FRAGMENT(locale)}
          },
          productCategories[] {
            ${coalesceQuery(
              'styles',
              locale,
              'styles',
              `
              [style->showInLocales[$locale] != false] {
                ...style->_id,
              }`,
            )}
          }
        }
      } {
        ...,
        productCategories[length(styles) > 0]
      },
      secondaryMenuItems[] {
        _type == 'reference' => @-> {
          ${LINK_FRAGMENT(locale)}
        },
        _type == 'secondaryMenuItem' => {
          _type,
          ${coalesceQuery('secondaryMenuItemTitle', locale)},
          secondarySubmenuItems[]-> {
            ${LINK_FRAGMENT(locale)}
          },
          defined(secondaryMenuCard) => {
            secondaryMenuCard {
              ${coalesceQuery('title', locale)},
              ${MENU_CARD_FRAGMENT(locale)}
            }
          }
        }
      }
    } {
      ...,
      mainMenuItems[_type == 'link' || length(productCategories) > 0] {
        _key,
        _type,
        mainMenuItemLink,
        _type == 'link' => {
          type,
          linkText,
          defined(newTab) => {
            newTab,
          },
          defined(page) => {
            page,
          },
          defined(externalLink) => {
            externalLink,
          },
          defined(email) => {
            email,
          },
          defined(phone) => {
            phone,
          },
          defined(scrollToSection) => {
            scrollToSection,
          },
        }
      }
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch({ query, draftMode, params })
}

export const GetNavigationResponseSchema = z.object({
  wishlistSlug: z.object({
    slug: SlugSchema,
  }),
  mainMenuItems: z.array(z.union([MainMenuItemSchema, LinkSchema, MainMenuItemSchema.and(LinkSchema)])).optional(),
  secondaryMenuItems: z.array(z.union([SecondaryMenuItemSchema, LinkSchema, SecondaryMenuItemSchema.and(LinkSchema)])).optional(),
  topMenuCards: z.array(MenuCardSchema),
})

export type GetNavigationResponse = z.infer<typeof GetNavigationResponseSchema>

export async function getNavigation(locale = DEFAULT_LOCALE, draftMode = false): Promise<GetNavigationResponse> {
  const query = defineQuery(`
    *[_type == "navigation"][0] {
      "wishlistSlug": *[
        _type == 'pageWishlist'
      ][0] {
        ${coalesceQuery('slug', locale)},
      },
      mainMenuItems[] {
        ${MAIN_MENU_ITEM_FRAGMENT(locale)}
      },
      secondaryMenuItems[] {
        _type == 'reference' => @-> {
          ${LINK_FRAGMENT(locale)}
        },
        _type == 'secondaryMenuItem' => {
          _type,
          ${coalesceQuery('secondaryMenuItemTitle', locale)},
          secondarySubmenuItems[]-> {
            ${LINK_FRAGMENT(locale)}
          },
          defined(secondaryMenuCard) => {
            secondaryMenuCard {
              ${coalesceQuery('title', locale)},
              ${MENU_CARD_FRAGMENT(locale)}
            }
          }
        }
      },
      topMenuCards[]-> {
        ${MENU_CARD_FRAGMENT(locale)}
      }
    } {
      ...,
      mainMenuItems[_type == 'link' || length(productCategories) > 0]
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch({ query, draftMode, params })
}

export const GetFooterResponseSchema = z.object({
  findStoreHeader: z.string(),
  findStoreText: z.string(),
  findStoreLink: LinkSchema,
  findStoreImage: MediaSchema,
  infoSectionHeader: z.string(),
  emailSectionHeader: z.string(),
  emailSectionText: z.string(),
  emailSectionPlaceholder: z.string(),
  emailSectionSubscribe: z.string(),
  openingHoursHeader: z.string(),
  faqLink: LinkSchema,
  returnsLink: LinkSchema,
  productMenuItemsHeader: z.string(),
  productMenuItems: z.array(FooterMenuItemSchema),
  supportMenuItemsHeader: z.string(),
  supportMenuItems: z.array(FooterMenuItemSchema),
  extraMenuItems: z.array(LinkSchema),
  reviewExplanationTooltip: z.string(),
  recentlyViewed: z.string(),
  clear: z.string(),
  copyright: z.string(),
  bCorpLink: LinkSchema,
  bCorpNoticeLink: LinkSchema,
  customerServiceData: CustomerServiceSchema,
})

export type GetFooterResponse = z.infer<typeof GetFooterResponseSchema>

export async function getFooter(locale = DEFAULT_LOCALE, draftMode = false): Promise<GetFooterResponse> {
  const query = defineQuery(`
    *[_type == "footer"][0] {
      _type,
      ${coalesceQuery('findStoreHeader', locale)},
      ${coalesceQuery('findStoreText', locale)},
      findStoreLink-> {
        ${LINK_FRAGMENT(locale)}
      },
      findStoreImage-> {
        ${MEDIA_FRAGMENT()}
      },
      ${coalesceQuery('infoSectionHeader', locale)},
      ${coalesceQuery('emailSectionHeader', locale)},
      ${coalesceQuery('emailSectionText', locale)},
      ${coalesceQuery('emailSectionPlaceholder', locale)},
      ${coalesceQuery('emailSectionSubscribe', locale)},
      ${coalesceQuery('openingHoursHeader', locale)},
      faqLink-> {
        ${LINK_FRAGMENT(locale)}
      },
      returnsLink-> {
        ${LINK_FRAGMENT(locale)}
      },
      ${coalesceQuery('productMenuItemsHeader', locale)},
      productMenuItems[showInLocales[$locale] != false] {
        ${FOOTER_MENU_ITEM_FRAGMENT(locale)}
      },
      ${coalesceQuery('supportMenuItemsHeader', locale)},
      supportMenuItems[showInLocales[$locale] != false] {
        ${FOOTER_MENU_ITEM_FRAGMENT(locale)}
      },
      extraMenuItems[]-> {
        ${LINK_FRAGMENT(locale)}
      },
      ${coalesceQuery('reviewExplanationTooltip', locale)},
      ${coalesceQuery('recentlyViewed', locale)},
      ${coalesceQuery('clear', locale)},
      ${coalesceQuery('copyright', locale)},
      bCorpLink-> {
        ${LINK_FRAGMENT(locale)}
      },
      bCorpNoticeLink-> {
        ${LINK_FRAGMENT(locale)}
      },
      ${CUSTOMER_SERVICE_FRAGMENT(locale)}
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch({ query, draftMode, params })
}

const DiscountCampaignSchema = z.object({
  discount: z.number(),
  productAnnouncementBar: ProductAnnouncementBarSchema.optional(),
  showOnCollections: z.enum(['all', 'select', 'exclude']).optional(),
  productIds: z.array(z.string()),
  collectionIds: z.array(z.string()),
  showInNavigation: z.boolean().optional(),
  showInSectionAndPcpCards: z.boolean().optional(),
  showInFooter: z.boolean().optional(),
  discountPillBackgroundColor: z.string().optional(),
})

export const GetGlobalsResponseSchema = z.object({
  global: z.record(z.string(), z.string()),
  product: z.record(z.string(), z.string()),
  collection: z.record(z.string(), z.string()),
  colorOrder: z.array(z.string()),
  localizedSettings: z.object({
    showCurrencySymbol: z.boolean(),
  }),
  searchSettingsTerms: SearchSettingsTermsSchema,
  searchSettings: SearchSettingsSchema,
  discountCampaigns: z.array(DiscountCampaignSchema),
  stylesOrder: StylesOrderSchema,
})

export type GetGlobalsResponse = z.infer<typeof GetGlobalsResponseSchema>

export async function getGlobals(locale = DEFAULT_LOCALE, draftMode = false): Promise<GetGlobalsResponse> {
  const query = defineQuery(`
    {
      "global": *[_type == "globalTerms"][0] {
        ${GLOBAL_TERMS_FRAGMENT(locale)}
      },
      "product": *[_type == "globalProduct"][0] {
        ${GLOBAL_PRODUCTS_FRAGMENT(locale)}
      },
      "collection": *[_type == "globalCollection"][0] {
        ${GLOBAL_COLLECTIONS_FRAGMENT(locale)}
      },
      "colorOrder": *[_type == "colorOrder"].colors[]->._id,
      "localizedSettings": *[_type == "localizedSettings"] {
         "showCurrencySymbol": showCurrencySymbol[$locale] != false
      }[0],
      ...*[_type == "searchSettings"][0] {
        "searchSettingsTerms": {
          ${GLOBAL_SEARCH_SETTINGS_TERMS_FRAGMENT(locale)}
        },
        "searchSettings": {
          ${GLOBAL_SEARCH_SETTINGS_FRAGMENT(locale)}
        }
      },
      "discountCampaigns": *[_type == "discountCampaignSettings"][0].discountCampaigns[@->.showInLocales[$locale] != false]-> {
        discount,
        defined(announcementBar) => {
          'announcementBar': announcementBar-> {
            ${ANNOUNCEMENT_BAR_FRAGMENT(locale)}
          }
        },
        defined(productAnnouncementBar) => {
          productAnnouncementBar-> {
            ${PRODUCT_ANNOUNCEMENT_BAR_FRAGMENT(locale)}
          }
        },
        showOnCollections,
        "productIds":collections[]->.products[]->_id,
        "collectionIds":collections[]->._id,
        defined(showInNavigation) => {
          showInNavigation
        },
        defined(showInSectionAndPcpCards) => {
          showInSectionAndPcpCards
        },
        defined(showInFooter) => {
          showInFooter
        },
        defined(discountPillBackgroundColor) => {
          'discountPillBackgroundColor': discountPillBackgroundColor.value
        },
      },
      "stylesOrder": ${STYLES_ORDER_FRAGMENT(locale)}
    }
  `)

  const params = {
    locale,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch({ query, draftMode, params })
}

export const getStyleOrder = async (locale = DEFAULT_LOCALE, draftMode = false): Promise<StylesOrder> => {
  const query = defineQuery(STYLES_ORDER_FRAGMENT(locale))

  return await sanityFetch({ query, draftMode })
}

export const GetDiscountCampaignForFooterSchema = z
  .array(
    z.object({
      discount: z.number(),
      showOnCollections: z.enum(['all', 'select', 'exclude']),
      collectionIds: z.array(z.string()),
      showInFooter: z.boolean().optional(),
    }),
  )
  .nullable()

export type GetDiscountCampaignForFooter = z.infer<typeof GetDiscountCampaignForFooterSchema>

export const getDiscountCampaignForFooter = async (locale = DEFAULT_LOCALE, draftMode = false): Promise<GetDiscountCampaignForFooter> => {
  const query = defineQuery(`
    *[_type == "discountCampaignSettings"][0].discountCampaigns[@->.showInLocales[$locale] != false]-> {
      discount,
      showOnCollections,
      "collectionIds":collections[]->._id,
      defined(showInFooter) => {
        showInFooter
      }
    }
    `)

  const params = {
    locale,
  }

  return await sanityFetch({ query, draftMode, params })
}

export type getCookieBannerResponse = CookieBanner

export async function getCookieBanner(locale = DEFAULT_LOCALE, draftMode = false): Promise<getCookieBannerResponse> {
  const query = defineQuery(`
    *[_type == "cookies"][0] {
      ${COOKIE_BANNER_FRAGMENT(locale)}
    }
  `)

  return validate(CookieBannerSchema, await sanityFetch({ query, draftMode }), {
    errorMessage: 'Failed to validate cookie banner',
    enabled: !draftMode,
  })
}

export type GetCartResponse = Cart

export async function getCart(locale = DEFAULT_LOCALE, draftMode = false): Promise<GetCartResponse> {
  const query = defineQuery(`
    *[_type == "cart"][0] {
      ${CART_FRAGMENT(locale)}
    }
  `)

  const params = {
    locale,
  }

  return validate(CartSchema, await sanityFetch({ query, draftMode, params }), {
    errorMessage: 'Failed to validate cart',
    enabled: !draftMode,
  })
}

const ArticleCountSchema = z.number()

export type GetArticleCountResponse = z.infer<typeof ArticleCountSchema>

export async function getArticleCount(id: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<GetArticleCountResponse> {
  //  Find the section article overview which has a parent with the id
  const query = defineQuery(`
    count(
      *[
        _type in ['pageArticle', 'pagePressRelease']
        && showInLocales[$locale] != false
        && (
          *[
            _type == 'sectionArticleOverview'
            && ^._id in articles[]._ref
            && (
              *[_id == $id && ^._id in sections[]._ref][0] != null
              && showInLocales[$locale] != false
            )
          ][0] != null
        )
      ]
    )
  `)

  const params = {
    id,
    locale,
  }

  return validate(ArticleCountSchema, await sanityFetch({ query, draftMode, params }), {
    errorMessage: 'Failed to validate article count',
    enabled: !draftMode,
  })
}

export const GetAllPagesInLocaleResponseSchema = z.array(
  z.object({
    _id: z.string(),
    _type: z.string(),
    slug: SlugSchema,
    allSlugs: z.record(z.string(), SlugSchema),
    seo: z
      .object({
        noIndex: z.boolean(),
      })
      .optional(),
  }),
)

export type GetAllPagesInLocaleResponse = z.infer<typeof GetAllPagesInLocaleResponseSchema>

/**
 * This fetch is used only for the [...slug].tsx page and should be changed in the future
 */
export async function getAllPagesInLocale(docs: string[], locale = DEFAULT_LOCALE): Promise<GetAllPagesInLocaleResponse> {
  const query = defineQuery(`
    *[
      _type in $docs
      && length(${coalesceFilter('slug', locale)}.current) > 0
      && showInLocales[$locale] != false
    ] {
      _id,
      _type,
      ${coalesceQuery('slug', locale)},
      "allSlugs": ${forceFallbackForAll('slug')},
      defined(seo) => {
        seo {
          "noIndex": coalesce(noIndex, false)
        }
      }
    }
  `)

  const params = {
    docs,
    locale,
  }

  return validate(GetAllPagesInLocaleResponseSchema, await sanityFetch({ query, params }), {
    errorMessage: 'Failed to validate nested page response',
    enabled: true,
  })
}

export const getAllPagePaths = async (docs: string[], locales: SupportedLocale[]): Promise<{ [key: string]: string }[]> => {
  const query = defineQuery(`
    *[
      _type in $docs
    ] {
      ${locales
        .map(
          (locale) => `
        '${locale}': select(showInLocales['${locale}'] != false => ${coalesceFilter('slug', locale)}.current)
      `,
        )
        .join(', ')}
    }
  `)

  const params = {
    docs,
  }

  return await sanityFetch({ query, params })
}

export const GetAllPagesInLocaleForSiteMapResponseSchema = z.array(
  z.object({
    _type: z.string(),
    slug: SlugSchema,
    allSlugs: z.record(z.string(), SlugSchema),
    showInLocales: z.record(z.string(), z.union([z.boolean(), z.string()])).optional(),
  }),
)

export type GetAllPagesInLocaleForSiteMapResponse = z.infer<typeof GetAllPagesInLocaleForSiteMapResponseSchema>

export const getAllPagesInLocaleForSiteMap = async (docs: string[], locale = DEFAULT_LOCALE): Promise<GetAllPagesInLocaleForSiteMapResponse> => {
  const query = defineQuery(`
    *[
      _type in $docs
      && showInLocales[$locale] != false
      && seo.noIndex != true
    ] {
      _type,
      ${coalesceQuery('slug', locale)},
      "allSlugs": ${forceFallbackForAll('slug')},
      defined(showInLocales) => {
        showInLocales
      },
    }
  `)

  const params = {
    docs,
    locale,
  }

  return validate(GetAllPagesInLocaleForSiteMapResponseSchema, await sanityFetch({ query, params }), {
    errorMessage: 'Failed to validate article count',
    enabled: true,
  })
}

export const GetAllProductPagesInLocaleForSiteMapResponseSchema = z.array(
  z.object({
    _id: z.string(),
    _type: z.string(),
    productTitle: z.record(z.string(), z.string()),
    slug: SlugSchema,
    images: z.array(z.string()),
    availableInLocale: z.record(z.string(), z.boolean()),
  }),
)

export type GetAllProductPagesInLocaleForSiteMapResponse = z.infer<typeof GetAllProductPagesInLocaleForSiteMapResponseSchema>

/**
 * This fetch returns all a paginated list of all the indexable product pages in a locale as well as their availability in the other locales
 **/
export async function getAllProductPagesInLocaleForSiteMap(locale = DEFAULT_LOCALE, from: number, to: number): Promise<GetAllProductPagesInLocaleForSiteMapResponse> {
  const query = defineQuery(`
    *[
      _type == "product"
      && length(coalesce(slug.current, '')) > 0
      && showInLocales[$locale] != false
      && availableInShopify[$locale]
      && !seo.noIndex
    ][$from...$to] {
      _id,
      _type,
      ${forceFallbackForAll('productTitle')},
      slug,
      'images': array::compact([
        ...images[_type == 'image'].asset->url,
        ...images[_type == 'reference']->.asset->url
      ]),
      availableInShopify,
      'productShowInLocales': showInLocales,
      'primaryCollectionShowInLocales': *[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0].showInLocales,
    } {
      _id,
      _type,
      slug,
      productTitle,
      images,
      ${requireAllToBeTrue('availableInLocale', ['availableInShopify', 'productShowInLocales', 'primaryCollectionShowInLocales'])}
    }
  `)

  const params = {
    locale,
    from,
    to,
  }

  return validate(GetAllProductPagesInLocaleForSiteMapResponseSchema, await sanityFetch({ query, params }), {
    errorMessage: 'Failed to validate all product pages in locale for sitemap',
    enabled: true,
  })
}

type getProductsForRecommendationsResponse = ProductCardProduct[]

export const getProductsForRecommendations = async (slugs: string[], locale = DEFAULT_LOCALE, maxProducts = 30): Promise<getProductsForRecommendationsResponse> => {
  const query = defineQuery(`
    *[_type == 'product'
    && slug.current in $slugs
    && availableInShopify[$locale] && showInLocales[$locale] != false] {
      ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale)},
      "sortOrder": select(${slugs.map((slug, i) => `slug.current == '${slug}' => ${i + 1}`).join(', ')}, ${maxProducts + 1})
    } | order(sortOrder asc) [0...${maxProducts}]
  `)

  const params = {
    slugs,
    locale,
    maxProducts,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return validate(z.array(ProductCardProductSchema), await sanityFetch({ query, params }), {
    errorMessage: 'Failed to validate products for recommendations',
    enabled: true,
  })
}

export type GetDefaultServiceUspsResponse = ServiceUspSet

export const getDefaultServiceUsps = async (locale = DEFAULT_LOCALE, draftMode = false): Promise<GetDefaultServiceUspsResponse> => {
  const query = defineQuery(`
    *[
      _type in ["globalServiceUsps"]
    ][0].defaultServiceUsps->{
      ${SERVICE_USPS_SET_FRAGMENT(locale)}
    }
  `)

  return validate(ServiceUspsSchema, await sanityFetch({ query, draftMode }), {
    errorMessage: 'Failed to validate default service usps',
    enabled: !draftMode,
  })
}

type getLocalizedGlobalServiceUspsResponse = ServiceUspSet

export const getLocalizedGlobalServiceUsps = async (geo: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<getLocalizedGlobalServiceUspsResponse> => {
  const query = defineQuery(`
    *[
      _type == "globalServiceUsps"
    ][0][$localizedUsps]-> {
      ${SERVICE_USPS_SET_FRAGMENT(locale)}
    }
  `)

  const params = {
    localizedUsps: `${geo}ServiceUsps`,
  }

  return validate(ServiceUspsSchema, await sanityFetch({ query, draftMode, params }), {
    errorMessage: 'Failed to validate localized global service usps',
    enabled: !draftMode,
  })
}

const GetDiscountMessagesResponseSchema = z.object({
  discountSuccessMessage: z.string(),
  discountFailureMessage: z.string(),
})

export async function getDiscountMessages(locale = DEFAULT_LOCALE, draftMode = false) {
  const query = defineQuery(`
  *[_type == "discountTerms"][0] {
    ${coalesceQuery('discountSuccessMessage', locale)},
    ${coalesceQuery('discountFailureMessage', locale)}
  }
  `)

  return validate(GetDiscountMessagesResponseSchema, await sanityFetch({ query, draftMode }), {
    errorMessage: 'Failed to validate discount messages',
    enabled: !draftMode,
  })
}

export async function getSectionTypes(docs: string[], locale = DEFAULT_LOCALE, slug?: string, draftMode = false) {
  const isProduct = docs.includes('product')
  const isPageCollection = docs.includes('pageCollection')

  const defaultQuery = defineQuery(`
    *[_type in $docs ${isProduct && slug ? `&& slug.current == $slug` : slug ? `&& ${coalesceFilter('slug', locale)}.current == $slug` : ''}].sections[]->{
      ${SECTION_TYPES_FRAGMENT(locale)}
    }
  `)

  const pageCollectionQuery = defineQuery(`
    coalesce(
      *[_type == 'pageCollection' && length(connectedCollection[${coalesceFilter('@->slug', locale)}.current == $slug]) > 0][0],
      *[_id == 'defaultCollectionTemplate'][0]
    ).sections[]-> {
      ${SECTION_TYPES_FRAGMENT(locale)}
    }
  `)

  const params = {
    docs,
    locale,
    slug: slug || '',
  }

  const query = isPageCollection ? pageCollectionQuery : defaultQuery

  const response: SectionType[] | [] = (await sanityFetch({ query, draftMode, params })) || []

  return response.filter((each) => each?._type)
}

export const getSectionTypesForPrimaryCollectionFromProduct = async (slug: string, locale = DEFAULT_LOCALE, draftMode = false) => {
  const query = defineQuery(`
    *[_type == 'collection' && isPrimary == true && $slug in products[]->slug.current].sections[]->{
      ${SECTION_TYPES_FRAGMENT(locale)}
    }
  `)

  const params = {
    slug,
    locale,
  }

  const response: SectionType[] | [] = await sanityFetch({ query, draftMode, params })

  return response?.filter((each) => each?._type)
}

// In the future we need to spend some time checking the sections schema in this specific case. This is breaking all the time when importing the SectionSchema
const getHomePageResponseSchema = z.object({
  _id: z.string(),
  showAnnouncementBar: z.boolean(),
  showFooterRecommendations: z.boolean().nullable(),
  sections: z.any(),
  seo: SeoSchema,
})

export type GetHomePageResponse = z.infer<typeof getHomePageResponseSchema>

export async function getHomePage(locale = DEFAULT_LOCALE, draftMode = false): Promise<GetHomePageResponse> {
  const sectionTypes = await getSectionTypes(['homePage'], locale, '/', draftMode)

  const query = defineQuery(`
    *[_type == "homePage" && ${coalesceFilter('slug', locale)}.current == "/"][0] {
      _id,
      showAnnouncementBar,
      showFooterRecommendations,
      ${SECTIONS_FRAGMENT(locale, sectionTypes, '/')},
      seo {
        ${SEO_FRAGMENT(locale)}
      },
    }
  `)

  const params = {
    locale,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return validate(getHomePageResponseSchema, await sanityFetch({ query, draftMode, params }), {
    errorMessage: 'Failed to validate the home page settings',
    enabled: !draftMode,
  })
}

export interface GetPageCollectionResponse {
  _id: string
  slug: Slug
  collectionId: string
  collectionTitle: string
  sections: Section[]
  collectionSeason?: CollectionSeason
  collectionProductType?: ProductType
  seo?: Seo
}

// This function fetches the data for a pageCollection (new structure) and when not available the data from the collection (old structure)
export async function getPageCollection(slug: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<GetPageCollectionResponse> {
  const sectionTypes = await getSectionTypes(['pageCollection'], locale, slug)

  const query = defineQuery(`
    {
      'page': coalesce(
        *[_type == 'pageCollection' && length(connectedCollection[${coalesceFilter('@->slug', locale)}.current == $slug]) > 0][0],
        *[_id == 'defaultCollectionTemplate'][0]
      ) {
        connectedCollection,
        ${SECTIONS_FRAGMENT(locale, sectionTypes, slug)}
      }
    } {
      ...page,
      _id,
      ...coalesce(
        page.connectedCollection[0]->,
        *[_type == 'collection' && showInLocales[$locale] != false && ${coalesceFilter('slug', locale)}.current == $slug][0]
      ) {
        _id,
        'collectionId': _id,
        ${coalesceQuery('title', locale, 'collectionTitle')},
        defined(seo) => {
          seo {
            ${SEO_FRAGMENT(locale)}
          }
        },
        ${coalesceQuery('slug', locale)},
        defined(collectionSeason) => {
          collectionSeason
        },
        defined(productType) => {
          "collectionProductType": productType
        }
      }
    }
  `)

  const params = {
    slug,
    locale,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch({ query, draftMode, params })
}

export interface GetPageErrorResponse {
  _id: string
  _type: string
  showFooterRecommendations: boolean
  sections: Section[]
  seo: Seo
}

export async function getPageError(docs: string[], locale: SupportedLocale = DEFAULT_LOCALE, draftMode = false): Promise<GetPageErrorResponse> {
  const sectionTypes = await getSectionTypes(docs, locale)

  const query = defineQuery(`
    *[_type in $docs][0] {
      _id,
      _type,
      showFooterRecommendations,
      ${SECTIONS_FRAGMENT(locale, sectionTypes)},
      seo {
        ${SEO_FRAGMENT(locale)}
      },
    }
  `)

  const params = {
    locale,
    docs,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch({ query, draftMode, params })
}

export interface GetPagesResponse {
  _id: string
  _type: string
  showFooterRecommendations: boolean
  sections: Section[]
  seo: Seo
}

export async function getPages(docs: string[], slug: string, locale: SupportedLocale = DEFAULT_LOCALE, draftMode = false): Promise<GetPagesResponse> {
  const sectionTypes = await getSectionTypes(docs, locale, slug, draftMode)

  const query = defineQuery(`
    *[_type in $docs && showInLocales[$locale] != false && ${coalesceFilter('slug', locale)}.current == $slug][0] {
      _id,
      _type,
      showFooterRecommendations,
      ${SECTIONS_FRAGMENT(locale, sectionTypes, slug)},
      seo {
        ${SEO_FRAGMENT(locale)}
      },
    }
  `)

  const params = {
    docs,
    locale,
    slug,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch({ query, draftMode, params })
}

export interface GetNestedPagesResponse {
  _id: string
  _type: string
  showFooterRecommendations: boolean
  // TODO: This isn't strictly true. I could also be the Page Article which I'm unsure why I put into the sections array.
  sections: Section[]
  seo: Seo
}

export async function getNestedPages(
  docs: string[],
  lastSlug: string,
  firstSlug: string,
  locale: SupportedLocale = DEFAULT_LOCALE,
  draftMode = false,
): Promise<GetNestedPagesResponse> {
  const sectionTypes = await getSectionTypes(docs, locale, firstSlug)

  // This query either produces a blog overview page (could be paginated) or an article page
  const query = defineQuery(`
    *[
      _type in $docs
      && showInLocales[$locale] != false
      && select(
        ${coalesceFilter('slug', locale)}.current == $lastSlug => true,
        ${coalesceFilter('slug', locale)}.current == $firstSlug => true,
        false
      )
    ][0] {
      _id,
      _type,
      showFooterRecommendations,
      ${SECTIONS_FRAGMENT(locale, sectionTypes, firstSlug, lastSlug)},
      _type == 'pageArticle' || _type == 'pagePressRelease' => {
        "sections": [
          {
            ${PAGE_ARTICLE_FRAGMENT(locale)}
          }
        ]
      },
      seo {
        ${SEO_FRAGMENT(locale)}
      },
    }
  `)

  const params = {
    docs,
    locale,
    lastSlug,
    firstSlug,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch({ query, draftMode, params })
}

export const VideoUrlSchema = z.object({
  _type: z.literal('videoUrl'),
  url: z.string(),
  urlDesktop: z.string().optional(),
})

export type VideoUrl = z.infer<typeof VideoUrlSchema>

export const ProductMediaSchema = z.union([ImageSchema, HotspotImageSchema, VideoUrlSchema])

export type ProductMedia = z.infer<typeof ProductMediaSchema>
export interface ProductVariant {
  shopifyVariantId: ShopifyProductVariantId
  price: number
  sku: string
  inventory: {
    quantity: number
  }
  options: {
    name: string
    value: string
  }[]
  isAvailable: boolean
  title: string
}

export interface GetProductPageResponse {
  _id: string
  productTitle: string
  productOverrideCare?: CareInstructionSet
  models: ModelBio[]
  shopifyProductId: ShopifyProductId
  isAvailable: boolean
  price: number
  variants: ProductVariant[]
  currentColor: Color
  description?: RichText
  adImages: Image[]
  productMedia: ProductMedia[]
  discontinued: boolean
  sections?: Section[]
  productServiceUsps?: {
    serviceUsps: {
      icon: 'tick' | 'warning'
      text: RichText
      openAccordionItem: 'description' | 'sizing' | 'material' | 'details' | 'policies'
    }[]
  }
  sustainability: SustainabilityInformation
  hasSizeGuide: boolean
  seo?: Seo
  usps?: ImageTextListItem[]
  showReviews: boolean
  primaryCollectionId: string
  primaryCollectionSlug: Slug
  primaryCollectionTitle: string
  primaryCollectionProductType: ProductType
  primaryCollectionSeason: CollectionSeason
  breadcrumb: CollectionBreadcrumb
  productAnnouncementBar?: ProductAnnouncementBar
  productSizeInfoNote?: ProductSizeInfoNote
  colors?: ProductColor[]
  collectionBulletImage?: Media
  collectionSections?: Section[]
  sizeCharts?: SizeChart[]
  sizing?: RichText
  materials?: {
    percentage: number
    materialName: string
    icon: Image
    materialDescription?: RichText
  }[]
  emissions?: Emissions
  care?: CareInstructionSet
  organic: boolean
  recycled: boolean
  mulesingFree: boolean
}

export async function getProductPage(slug: string, locale: SupportedLocale = DEFAULT_LOCALE, draftMode = false): Promise<GetProductPageResponse> {
  const productSectionTypes = await getSectionTypes(['product'], locale, slug)
  const collectionSectionTypes = await getSectionTypesForPrimaryCollectionFromProduct(slug, locale)

  const query = defineQuery(`
    *[
      _type == 'product' &&
      slug.current == $slug &&
      showInLocales[$locale] != false &&
      ${draftMode ? '' : `availableInShopify[$locale] &&`}
      (*[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0].showInLocales[$locale]) != false
    ][0] {
      _id,
      ${coalesceQuery('productTitle', locale)},
      defined(care) => {
        "productOverrideCare": care-> {
          ${CARE_INSTRUCTION_SET_FRAGMENT(locale)}
        }
      },
      defined(emissions) => {
        "productOverrideEmissions": emissions-> {
          ${EMISSIONS_FRAGMENT}
        }
      },
      defined(materials) => {
        'productOverrideMaterials': materials[] {
          percentage,
          ...material->{
            ${coalesceQuery('materialName', locale)},
            defined(materialDescription.en) => {
              ${coalesceQuery('materialDescription', locale)}
            },
            icon {
              ${GET_IMAGE_FRAGMENT()}
            }
          },
        },
        'productOverrideOrganic': length(materials[material->organic == true]) > 0,
        'productOverrideRecycled': length(materials[material->recycled == true]) > 0,
        'productOverrideMulesingFree': length(materials[material->mulesingFree == true]) > 0
      },
      models[]->{
        ${MODEL_BIO_FRAGMENT()}
      },
      'shopifyProductId': shopifyProductId[$locale],
      'isAvailable': count(
        shopifyVariants[@->inventory[locationId == $inventoryLocationId][0].quantity > 0]
      ) > 0 && availableInShopify[$locale],
      'price': shopifyVariants[0]->price[$locale],
      'variants': shopifyVariants[]-> {
        'shopifyVariantId': shopifyId[$locale],
        'price': price[$locale],
        sku,
        inventory[locationId == $inventoryLocationId][0] {
          quantity
        },
        options[] {
          name,
          'value': value[$locale]
        },
        'isAvailable': inventory[locationId == $inventoryLocationId][0].quantity > 0,
        'title': array::join(options[].value[$locale], ' / ')
      },
      'currentColor': color-> {
        ${COLOR_FRAGMENT(locale)}
      },
      defined(description) => {
        ${RICH_TEXT_FRAGMENT(locale, 'description')}
      },
      'adImages': coalesce(adImages[0...2] {
        _type == 'image' => {
          ${GET_IMAGE_FRAGMENT()}
        },
      }, []),
      'productMedia': images[] {
        _type == 'image' => {
          ${GET_IMAGE_FRAGMENT()}
        },
        _type == 'reference' || _type == 'taggedImage' => @-> {
          ${HOTSPOT_IMAGE_FRAGMENT(locale)}
        },
        _type == 'videoUrl' => {
          _type,
          url,
          urlDesktop,
        },
      },
      'discontinued': coalesce(discontinued[$locale], false),
      defined(sections) => {
        ${SECTIONS_FRAGMENT(locale, productSectionTypes, slug)}
      },
      defined(productServiceUsps) => {
        productServiceUsps-> {
          serviceUsps[^.showInLocales[$locale] != false]-> {
            icon,
            ${RICH_TEXT_FRAGMENT(locale, 'text')},
            openAccordionItem
          }
        }
      },
      defined(productUsps) && count(productUsps) > 0 => {
       'productUspsOverride': productUsps[]-> {
          ${IMAGE_TEXT_LIST_ITEM_FRAGMENT(locale)}
        }
      },
      'sustainability': ${SUSTAINABILITY_INFORMATION_FRAGMENT(locale)},
      defined(seo) => {
        seo {
          ${SEO_FRAGMENT(locale)}
        }
      },
      ...*[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0] {
        "primaryCollectionId": _id,
        defined(product.usps) && count(product.usps) > 0 => {
          'usps': product.usps[]-> {
            ${IMAGE_TEXT_LIST_ITEM_FRAGMENT(locale)}
          }
        },
        "hasSizeGuide": length(sizes) > 0,
        defined(showReviews) => {
          showReviews
        },
        ${coalesceQuery('slug', locale, 'primaryCollectionSlug')},
        ${coalesceQuery('title', locale, 'primaryCollectionTitle')},
        "primaryCollectionProductType": productType,
        "primaryCollectionSeason": collectionSeason,
        "breadcrumb": {
          ${COLLECTION_BREADCRUMB_FRAGMENT(locale)}
        },
        defined(productAnnouncementBars) => {
          'productAnnouncementBar': productAnnouncementBars[@->showInLocales[$locale] == true][0]-> {
            ${PRODUCT_ANNOUNCEMENT_BAR_FRAGMENT(locale)}
          }
        },
        defined(productSizeInfoNotes) => {
          'productSizeInfoNote': productSizeInfoNotes[@->showInLocales[$locale] == true][0]-> {
            ${PRODUCT_SIZE_INFO_NOTE_FRAGMENT(locale)}
          }
        },
        defined(products) => {
          'colors': products[@->._type == 'product' && @->availableInShopify[$locale] && @->showInLocales[$locale] != false && @->.color != null] -> {
            ${PRODUCT_COLOR_FRAGMENT(locale)}
          }
        },
        "collectionBulletImage": bulletImage-> {
          ${MEDIA_FRAGMENT()}
        },
        defined(sections) => {
          "collectionSections": ${SECTIONS_FRAGMENT(locale, collectionSectionTypes)}
        },
        defined(sizeCharts) => {
          ${SIZE_CHARTS_FRAGMENT(locale)}
        },
        defined(product.sizing) => {
          ${RICH_TEXT_FRAGMENT(locale, 'product.sizing', 'sizing')}
        },
        defined(materials) => {
          'materials': materials[] {
            percentage,
            ...material->{
              ${coalesceQuery('materialName', locale)},
              defined(materialDescription.en) => {
                ${coalesceQuery('materialDescription', locale)}
              },
              icon {
                ${GET_IMAGE_FRAGMENT()}
              }
            },
          },
          'organic': length(materials[material->organic == true]) > 0,
          'recycled': length(materials[material->recycled == true]) > 0,
          'mulesingFree': length(materials[material->mulesingFree == true]) > 0
        },
        defined(care) => {
          care-> {
            ${CARE_INSTRUCTION_SET_FRAGMENT(locale)}
          }
        },
        defined(emissions) => {
          emissions-> {
            ${EMISSIONS_FRAGMENT}
          },
        }
      }
    } {
      ...,
      _id,
      'care': coalesce(productOverrideCare, care),
      'materials': coalesce(productOverrideMaterials, materials),
      'emissions': coalesce(productOverrideEmissions, emissions),
      'organic': coalesce(productOverrideOrganic, organic),
      'recycled': coalesce(productOverrideRecycled, recycled),
      'mulesingFree': coalesce(productOverrideMulesingFree, mulesingFree),
      'usps': coalesce(productUspsOverride, usps),
    }
  `)

  const params = {
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
    locale,
    slug,
  }

  return await sanityFetch({ query, draftMode, params })
}

export interface GetProductSlugsByIdResponse {
  gid: string
  slug: Slug
}

export async function getProductSlugsById(ShopifyProductIds: string[], locale = DEFAULT_LOCALE, draftMode = false): Promise<GetProductSlugsByIdResponse[]> {
  const query = defineQuery(`
    *[
      _type == 'product' &&
      shopifyProductId[$locale] in $ShopifyProductIds
      && showInLocales[$locale] != false
    ] {
      'gid': shopifyProductId[$locale],
      slug
    }
  `)

  const params = {
    locale,
    ShopifyProductIds,
  }

  return await sanityFetch({ query, draftMode, params })
}

const GetPDPSettingsResponseSchema = z.object({
  storeAvailabilityMessage: z.string(),
  selectSizeMessage: z.string(),
  showOnlyInStockFilter: z.string(),
  itemNotAvailableInStores: z.string(),
})
export type GetPDPSettingsResponse = z.infer<typeof GetPDPSettingsResponseSchema>

export async function getPDPSettings(locale = DEFAULT_LOCALE, draftMode = false): Promise<GetPDPSettingsResponse> {
  const query = defineQuery(`
    *[_type == "pdpSettings"][0] {
      ${coalesceQuery('storeAvailabilityMessage', locale)},
      ${coalesceQuery('selectSizeMessage', locale)},
      ${coalesceQuery('showOnlyInStockFilter', locale)},
      ${coalesceQuery('itemNotAvailableInStores', locale)},
    }
  `)

  return validate(GetPDPSettingsResponseSchema, await sanityFetch({ query, draftMode }), {
    errorMessage: 'Failed to validate PDP settings',
    enabled: !draftMode,
  })
}

interface OpeningTime {
  closed?: boolean
  open?: string
  close?: string
}

interface SpecialOpeningTime {
  date: string
  open?: string
  close?: string
  closed?: boolean
}

export interface GetRetailStoresInfoResponse {
  shopifyId: string
  geoPoint: {
    lat: number
    lng: number
  }
  city: string
  street: string
  postalCode: string
  telephoneNumber: string
  country: string
  googleMapsLink: string
  defaultOpeningTimes: {
    monday: OpeningTime
    tuesday: OpeningTime
    wednesday: OpeningTime
    thursday: OpeningTime
    friday: OpeningTime
    saturday: OpeningTime
    sunday: OpeningTime
  }
  specialOpeningTimes: SpecialOpeningTime[] | []
  slug: Slug
}

export async function getRetailStoresInfo(locale = DEFAULT_LOCALE, draftMode = false): Promise<GetRetailStoresInfoResponse[]> {
  const query = defineQuery(`
    *[_type == "retailStore"] {
      shopifyId,
      geoPoint,
      ${coalesceQuery('city', locale)},
      street,
      postalCode,
      country,
      googleMapsLink,
      telephoneNumber,
      defaultOpeningTimes,
      specialOpeningTimes[]->,
      "slug": *[references(^._id) && _type == 'sectionStore'][0]._id,
    } {
      ...,
      "slug": *[references(^.slug)][0].slug[$locale] {
        current
      },
      "specialOpeningTimes": specialOpeningTimes[dateTime(date + 'T00:00:00Z') < dateTime(now()) + 60*60*24*14] {
        date,
        open,
        close,
        closed,
      }
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch({ query, draftMode, params })
}

export type GetWishlistProductInformationResponse = ProductCardProduct

export const getWishlistProductInformation = async (ids: string[], locale = DEFAULT_LOCALE, draftMode = false): Promise<GetWishlistProductInformationResponse[]> => {
  const query = defineQuery(`
    *[
      _type == 'product'
      && showInLocales[$locale] != false
      && shopifyProductId[$locale] in $ids
    ] {
      ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale)}
    }
  `)

  const params = {
    ids: ids.map((id) => `gid://shopify/Product/${id}`),
    locale,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch({ query, draftMode, params })
}

export const getCollectionsByType = async (productType: ProductType, locale = DEFAULT_LOCALE, draftMode = false): Promise<{ _id: string; title: string }[]> => {
  const query = defineQuery(`
    *[_type == 'collection' && isPrimary == true && showInLocales[$locale] != false && productType == $productType] {
      _id,
      ${coalesceQuery('title', locale)},
    }
  `)

  const params = {
    locale,
    productType,
  }

  return await sanityFetch({ query, draftMode, params })
}

export interface RangeValue {
  fromValue?: number
  toValue?: number
}

export type Unit = 'cm' | 'in'

export interface Size {
  sizeDefinition: string
  title: string
  [key: string]: string | number | RangeValue
}

export interface Measurement {
  name: string
  title: string
  rangeValue: number
  toolTipCopy?: string
  toolTipInfographic?: Image
}

export interface SizeConversionTable {
  headings: {
    heading: string
    isOurSize: boolean
  }[]
  sizeConversions: string[][]
}

export interface SizeDefinition {
  _id: string
  measurements: Measurement[]
  conversionTables?: SizeConversionTable[]
}

interface GetCollectionSizingInformationResponse {
  sizes: Size[]
  sizeDefinitions: SizeDefinition[]
  name: string
  title: string
  sizingDescription: RichText
  collectionImage?: Image
}

export const getCollectionSizingInformation = async (collectionId: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<GetCollectionSizingInformationResponse> => {
  const query = defineQuery(`
    *[_id == $collectionId][0] {
      "sizeDefinitionRefs": array::unique(sizes[]->sizeDefinition._ref),
      "collection": {
        name,
        ${coalesceQuery('title', locale)},
        defined(product.sizing) => {
          ${RICH_TEXT_FRAGMENT(locale, 'product.sizing', 'sizingDescription')}
        },
        'collectionImage': products[0]-> {
          'modelImage': coalesce(${PRODUCT_IMAGE_TYPE_FRAGMENT('MDL-F1')},${PRODUCT_IMAGE_TYPE_FRAGMENT('MDL-F2')}),
          'fallbackImage': images[_type == 'image'][1] {
            _type == 'image' => {
              ${GET_IMAGE_FRAGMENT()}
            }
          }
        } {
          'productImage': coalesce(modelImage, fallbackImage)
        }.productImage,
      },
      sizes[]-> {
        ...,
        'sizeDefinition': sizeDefinition._ref,
      },
    } {
      ...collection,
      sizes,
      'sizeDefinitions': *[
        _type == 'sizeDefinition'
        && _id in ^.sizeDefinitionRefs
      ] {
        _id,
        measurements[]-> {
          name,
          ${coalesceQuery('title', locale)},
          rangeValue,
          defined(toolTipCopy) => {
            ${coalesceQuery('toolTipCopy', locale)}
          },
          defined(toolTipInfographic) => {
            toolTipInfographic {
              ${GET_IMAGE_FRAGMENT()}
            }
          }
        },
        defined(conversionTables) => {
          conversionTables[]-> {
            headings[] {
              ${coalesceLocalizedValue('heading', locale)},
              isOurSize,
            },
            'sizeConversions': sizeConversions.rows[].cells
          }
        }
      }
    }
  `)

  const params = {
    locale,
    collectionId,
  }

  return await sanityFetch({ query, draftMode, params })
}

interface getProductTypePageResponse {
  title: string
  subcategories: {
    title: string
    styles: {
      slug: Slug
      title: string
      bulletImage?: Media
    }[]
  }[]
}

export const getProductTypePage = async (type: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<getProductTypePageResponse> => {
  const query = defineQuery(`
    *[_id == 'retailNavigation'][0].menuItems[slug.en.current == $type][0] {
      ${coalesceQuery('title', locale)},
      subcategories[] {
        ${coalesceQuery('title', locale)},
        styles[]-> {
          // We don't localize the slug here because we need it to match what is in the retail shopify
          'slug': slug.en,
          ${coalesceQuery('title', locale)},
          bulletImage-> {
            ${MEDIA_FRAGMENT()}
          }
        }
      }
    }
  `)

  const params = {
    type,
    locale,
  }

  return await sanityFetch({ query, params, draftMode })
}

interface getAllProductTypesResponse {
  title: string
  styles: {
    slug: Slug
    title: string
    bulletImage?: Media
  }[]
}

export const getAllProductTypes = async (locale = DEFAULT_LOCALE, draftMode = false): Promise<getAllProductTypesResponse[]> => {
  const query = defineQuery(`
    *[_id == 'retailNavigation'][0].menuItems[].subcategories[] {
      ${coalesceQuery('title', locale)},
      styles[]-> {
          // We don't localize the slug here because we need it to match what is in the retail shopify
        'slug': slug.en,
        ${coalesceQuery('title', locale)},
        bulletImage-> {
          ${MEDIA_FRAGMENT()}
        }
      }
    }
  `)

  return await sanityFetch({ query, draftMode })
}

const ProductTypeSchema = z.object({
  title: z.string(),
  slug: SlugSchema,
})

export type getProductTypesResponseSchema = z.infer<typeof ProductTypeSchema>

export const getProductTypes = async (locale = DEFAULT_LOCALE, draftMode = false): Promise<getProductTypesResponseSchema[]> => {
  const query = defineQuery(`
    *[_id == 'retailNavigation'][0].menuItems[] {
      ${coalesceQuery('title', locale)},
      ${coalesceQuery('slug', locale)},
    }
  `)

  return validate(z.array(ProductTypeSchema), await sanityFetch({ query, draftMode }), {
    errorMessage: 'Failed to validate the product types on the retail website',
    enabled: !draftMode,
  })
}

export const getTranslatedWord = async (word: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<string> => {
  const query = defineQuery(`
    array::compact(*[_type in ['retailDictionary', 'globalTerms', 'globalProduct', 'globalCollection']] {
      _type == 'retailDictionary' => {${coalesceLocalizedValue(word, locale)}},
      _type != 'retailDictionary' => {${coalesceQuery(word, locale)}}
    }.${word})[0]
  `)

  const translatedWord = await sanityFetch({ query, params: { word }, draftMode })

  if (!translatedWord) {
    return toSentenceCase(translatedWord)
  }

  return translatedWord
}

export interface getStoresResponse {
  street: string
  shopifyId: string
  city: string
  country: string
}

export const getStores = async (locale = DEFAULT_LOCALE, draftMode = false): Promise<getStoresResponse[]> => {
  const query = defineQuery(`
    *[_type == 'retailStore'] {
      shopifyId,
      street,
      ${coalesceQuery('city', locale)},
      country,
    }
  `)

  return await sanityFetch({ query, draftMode })
}

export interface ShopifyVariantInSanity {
  price: number
  sku: string
  options: {
    name: string
    value: string
  }[]
  title: string
}

export interface ShopifyProductInSanity {
  price: number
  slug: Slug
  image: Image
  productTitle: string
  variants: ShopifyVariantInSanity[]
}

export interface getCollectionProductsResponse {
  collectionId: string
  products: ShopifyProductInSanity[]
  showFilters: boolean
}

export const getCollectionProducts = async (collectionSlug: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<getCollectionProductsResponse> => {
  const query = defineQuery(`
  *[_type == 'collection' && slug.en.current == $collectionSlug && showInLocales[$locale] != false][0] {
    "collectionId": _id,
    products[@->availableInShopify[$locale] && @->showInLocales[$locale] != false]-> {
      slug,
      // We force EN so we always get euro prices
      'price': shopifyVariants[0]->price.en,
      'image': coalesce(images[0] {
        ${GET_IMAGE_FRAGMENT()}
      }, []),
      ${coalesceQuery('productTitle', locale)},
      'variants': shopifyVariants[]-> {
        'price': price[$locale],
        sku,
        options[] {
          name,
          'value': value[$locale],
        },
        "title": array::join(options[].value[$locale], ' / ')
      }
    },
  } {
    ...,
    "showFilters": count(array::unique(products[0].variants[].options[].value)) > 1,
  }`)

  const params = { locale, collectionSlug }

  return await sanityFetch({
    draftMode,
    query,
    params,
  })
}

export interface GetCollectionBySlugResponse {
  _id: string
  slug: Slug
}

export const getCollectionIdByProductSlug = async (productSlug: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<GetCollectionBySlugResponse> => {
  const query = defineQuery(`
  *[_type == 'product' && slug.current == $productSlug][0] {
    ...*[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0] {
      _id,
      'slug': slug[$locale]
    }
  }`)

  const params = { productSlug, locale }

  return await sanityFetch({ draftMode, query, params })
}

export interface getProductResponse {
  images: Image[]
  title: string
}

export const getProduct = async (productSlug: string, draftMode = false): Promise<getProductResponse> => {
  const query = defineQuery(`
    *[_type == 'product' && slug.current == $productSlug][0] {
      'images': coalesce(images[_type == 'image' || _type == 'reference'] {
        _type == 'image' => {
          ${GET_IMAGE_FRAGMENT()}
        },
        _type == 'reference' => @-> {
          ...image {
            ${GET_IMAGE_FRAGMENT()}
          }
        }
      }, []),
      title
    }
  `)

  const params = { productSlug }

  return await sanityFetch({ draftMode, query, params })
}

export const getB2bHomePage = async (draftMode = false) => {
  const sectionTypes = await getSectionTypes(['b2bHomePage'], DEFAULT_LOCALE)
  const query = defineQuery(`
    *[_type == 'b2bHomePage'][0] {
      ${SECTIONS_FRAGMENT(DEFAULT_LOCALE, sectionTypes, '/')},
    }
  `)

  return await sanityFetch({ query, draftMode })
}

export interface B2bHeaderData {
  menuItems: {
    title: string
    mainMenuCards: {
      image: Image
      slug: string
      title: string
    }[]
    subcategories: {
      title: string
      styles: {
        title: string
        slug: string
      }[]
    }[]
  }[]
}

export const getB2bHeader = async (): Promise<B2bHeaderData> => {
  const query = defineQuery(`
    *[_type == 'b2bNavigation'][0] {
      menuItems[] {
        title,
        mainMenuCards[] {
          image {${GET_IMAGE_FRAGMENT()}},
          'slug': collection->products[0]->.slug.current,
          'title': collection->.title.en
        },
        subcategories[] {
          title,
          styles[]-> {
            "title": title.en,
            'slug': products[0]->.slug.current,
          }
        }
      }
    }
  `)

  return await sanityFetch({ query })
}

export interface B2bPdpData {
  _id: string
  productType: ProductType
  title: string
  products: {
    _id: string
    color: Color
    productTitle: string
  }[]
}

export const getB2bPdpData = async (collectionSlug: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<B2bPdpData> => {
  const query = defineQuery(`
  *[_type == 'collection' && slug.en.current == $collectionSlug && showInLocales.en != false][0] {
    _id,
    productType,
    title,
    products[]-> {
      _id,
      color-> {
        ...
      },
      'productTitle': productTitle.en,
    },
    ...,
  }`)

  const params = { locale, collectionSlug }

  return await sanityFetch({
    draftMode,
    query,
    params,
  })
}

export interface B2bPdpProductData {
  _id: string
  images: Image[]
}

export const getB2bPdpProductData = async (productId: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<B2bPdpProductData> => {
  const query = defineQuery(`
  *[_type == 'product' && showInLocales.en != false][0] {
    _id,
    images,
  }`)

  const params = { locale, productId }

  return await sanityFetch({
    draftMode,
    query,
    params,
  })
}

export const getProductSlugByCollectionsSlug = async (productType: ProductType, locale = DEFAULT_LOCALE, draftMode = false): Promise<{ slug: string; title: string }[]> => {
  const query = defineQuery(`
    *[_type == 'collection' && isPrimary == true && showInLocales[$locale] != false && productType == $productType] {
      'slug': products[0]->slug.current,
      ${coalesceQuery('title', locale)},
    }
  `)

  const params = {
    locale,
    productType,
  }

  return await sanityFetch({ query, draftMode, params })
}

export interface b2bProductPageData {
  _id: string
  productTitle: string
  models: ModelBio[]
  shopifyProductId: string
  isAvailable: boolean
  price: number
  variants: ProductVariant[]
  currentColor: Color
  description?: RichText
  productMedia: Image[]
  discontinued?: boolean
  primaryCollectionId: string
  primaryCollectionSlug: Slug
  primaryCollectionTitle: string
  primaryCollectionProductType: ProductType
  breadcrumb: CollectionBreadcrumb
  colors?: ProductColor[]
}

export async function getB2bProductPage(slug: string, locale: SupportedLocale = DEFAULT_LOCALE, draftMode = false): Promise<b2bProductPageData> {
  const query = defineQuery(`
    *[
      _type == 'product' &&
      slug.current == $slug &&
      showInLocales[$locale] != false &&
      ${draftMode ? '' : `availableInShopify[$locale] &&`}
      (*[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0].showInLocales[$locale]) != false
    ][0] {
      _id,
      ${coalesceQuery('productTitle', locale)},
      models[]->{
        ${MODEL_BIO_FRAGMENT()}
      },
      'shopifyProductId': shopifyProductId[$locale],
      'isAvailable': count(
        shopifyVariants[@->inventory[locationId == $inventoryLocationId][0].quantity > 0]
      ) > 0 && availableInShopify[$locale],
      'price': shopifyVariants[0]->price[$locale],
      'variants': shopifyVariants[]-> {
        'shopifyVariantId': shopifyId[$locale],
        'price': price[$locale],
        sku,
        inventory[locationId == $inventoryLocationId][0] {
          quantity
        },
        options[] {
          name,
          'value': value[$locale]
        },
        'isAvailable': inventory[locationId == $inventoryLocationId][0].quantity > 0,
        'title': array::join(options[].value[$locale], ' / ')
      },
      'currentColor': color-> {
        ${COLOR_FRAGMENT(locale)}
      },
      defined(description) => {
        ${RICH_TEXT_FRAGMENT(locale, 'description')}
      },
      'productMedia': images[_type == 'image'] {
        _type == 'image' => {
          ${GET_IMAGE_FRAGMENT()}
        },
      },
      defined(discontinued[$locale]) => {
        'discontinued': discontinued[$locale]
      },

      ...*[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0] {
        "primaryCollectionId": _id,
        ${coalesceQuery('title', locale, 'primaryCollectionTitle')},
        "primaryCollectionProductType": productType,

        defined(products) => {
          'colors': products[@->._type == 'product' && @->availableInShopify[$locale] != false && @->showInLocales[$locale] != false && @->.color != null] -> {
            ${PRODUCT_COLOR_FRAGMENT(locale)}
          }
        },
      }
    }
  `)

  const params = {
    inventoryLocationId: 'process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID',
    locale,
    slug,
  }

  return await sanityFetch({ query, draftMode, params })
}

export const GetClubMarevistaTeaserResponseSchema = z.object({
  backgroundVideo: z.string(),
  bodyText: z.string(),
  signUpText: z.string(),
  placeholderText: z.string(),
  invalidEmailText: z.string(),
  successMessage: z.string(),
})

export type GetClubMarevistaTeaserResponse = z.infer<typeof GetClubMarevistaTeaserResponseSchema>

export const getClubMarevistaTeaser = async (locale = DEFAULT_LOCALE, draftMode = false): Promise<GetClubMarevistaTeaserResponse> => {
  const query = defineQuery(`
    *[_type == 'clubMarevistaTeaser'][0] {
      backgroundVideo,
      ${coalesceQuery('bodyText', locale)},
      ${coalesceQuery('signUpText', locale)},
      ${coalesceQuery('placeholderText', locale)},
      ${coalesceQuery('invalidEmailText', locale)},
      ${coalesceQuery('successMessage', locale)},
    }
  `)

  return await sanityFetch({ query, draftMode })
}

export const GetGeneralShippingInformationSchema = z.object({
  freeDeliveryCountries: z.array(z.string()).optional(),
  freeFromDeliveryCountries: z
    .array(
      z.object({
        countries: z.array(z.string()),
        price: z.number().optional(),
      }),
    )
    .optional(),
  freeReturnsCountries: z.array(z.string()).optional(),
  generalShippingTimesInformation: RichTextSchema,
  exchangesAndReturns: RichTextSchema.optional(),
  forOrdersAboveTo: z.string().optional(),
})

export type GetGeneralShippingInformationResponse = z.infer<typeof GetGeneralShippingInformationSchema>

export async function getGeneralShippingInformation(locale: SupportedLocale = DEFAULT_LOCALE, draftMode = false): Promise<GetGeneralShippingInformationResponse> {
  const query = defineQuery(`
  {
    'shipmentAreas': *[_type == 'shipmentArea'],
    'shippingTerms': *[_type == 'shippingTerms'][0]
  } {
    ${coalesceQuery('shipmentAreas[shipping->.costs.type == "free"].countries[]->.name', locale, 'freeDeliveryCountries')},
    "freeFromDeliveryCountries": shipmentAreas[shipping->.costs.type == 'freeFrom'] {
      ${coalesceQuery('countries[]->.name', locale, 'countries')},
      ${coalesceQuery('shipping->costs.freeFromPrice', locale, 'price')},
    },
    ${coalesceQuery('shipmentAreas[returns->.costs.type == "free"].countries[]->.name', locale, 'freeReturnsCountries')},
    ${coalesceQuery('shippingTerms.generalShippingTimesInformation', locale, 'generalShippingTimesInformation')},
    ${coalesceQuery('shippingTerms.exchangesAndReturns', locale, 'exchangesAndReturns')},
    ${coalesceQuery('shippingTerms.forOrdersAboveTo', locale, 'forOrdersAboveTo')},
  }
  `)

  return await sanityFetch({ query, draftMode })
}

const CostsSchema = z.object({
  description: z.string(),
  costs: z.discriminatedUnion('type', [
    z.object({
      type: z.literal('free'),
      description: z.string(),
    }),
    z.object({
      type: z.literal('freeFrom'),
      freeFromPrice: z.number(),
      otherwisePrice: z.number(),
      description: z.string(),
    }),
    z.object({
      type: z.literal('fixed'),
      price: z.number(),
      description: z.string(),
    }),
    z.object({
      type: z.literal('custom'),
      description: z.string(),
    }),
  ]),
})

const ShipmentAreaSchema = z.object({
  country: z.string(),
  deliveryTime: z.object({
    type: z.enum(['orderedBy', 'businessDays']),
    orderedBy: z.string().optional(),
    businessDays: z.number().optional(),
  }),
  shippingCost: CostsSchema.optional(),
  returnCosts: CostsSchema.optional(),
})

export const GetCountryShippingInformationSchema = z.object({
  shipmentArea: ShipmentAreaSchema,
  territoriesShipmentAreas: z.array(ShipmentAreaSchema).optional(),
  exchangesAndReturns: RichTextSchema.optional(),
})

export type ShipmentArea = z.infer<typeof ShipmentAreaSchema>
export type GetCountryShippingInformationResponse = z.infer<typeof GetCountryShippingInformationSchema>

export async function getCountryShippingInformation(countryCode: string, locale = DEFAULT_LOCALE, draftMode = false): Promise<GetCountryShippingInformationResponse> {
  const shipmentAreaQuery = `
    "deliveryTime": deliveryTime {
      type,
      orderedBy,
      businessDays
    },
    "shippingCost": shipping-> {
      ${coalesceQuery('description', locale)},
      costs {
        type,
        ${coalesceQuery('freeFromPrice', locale)},
        ${coalesceQuery('otherwisePrice', locale)},
        ${coalesceQuery('price', locale)},
      }
    },
    "returnCosts": returns-> {
      ${coalesceQuery('description', locale)},
      costs {
        type,
        ${coalesceQuery('freeFromPrice', locale)},
        ${coalesceQuery('otherwisePrice', locale)},
        ${coalesceQuery('price', locale)},
      }
    }
  `

  const query = defineQuery(`
  {
    'shipmentArea': *[_type == 'shipmentArea' && upper($countryCode) in countries[@->._type == 'country']->.countryCode][0],
    'territoriesShipmentAreas': *[_type == 'shipmentArea' && upper($countryCode) in countries[@->._type == 'territory']->.territoryOf->.countryCode],
    'shippingTerms': *[_type == 'shippingTerms'][0]
  } {
    shipmentArea {
      ${coalesceQuery('countries[upper($countryCode) == @->.countryCode][0]->.name', locale, 'country')},
      ${shipmentAreaQuery}
    },
    territoriesShipmentAreas[] {
      ${coalesceQuery('countries[upper($countryCode) == @->.territoryOf->.countryCode][0]->.name', locale, 'country')},
      ${shipmentAreaQuery}
    },
    ${coalesceQuery('shippingTerms.exchangesAndReturns', locale, 'exchangesAndReturns')},
  }
  `)

  const params = {
    countryCode,
  }

  return await sanityFetch({ query, draftMode, params })
}

export const GetAllShippingCountriesSchema = z.array(
  z.object({
    name: z.string(),
    countryCode: z.string(),
  }),
)

export type GetAllShippingCountriesResponse = z.infer<typeof GetAllShippingCountriesSchema>

export async function getAllShippingCountries(locale: SupportedLocale = DEFAULT_LOCALE, draftMode = false): Promise<GetAllShippingCountriesResponse> {
  const query = defineQuery(`
  {
    "allCountries": *[_type == 'shipmentArea'].countries[@->._type == 'country' && defined(@->.countryCode) && defined(@->.name.en)]-> {
      countryCode,
      ${coalesceQuery('name', locale)},
    }
  }.allCountries
  `)

  return await sanityFetch({ query, draftMode })
}

export const getRecommendationFilterDataSchema = z.object({
  activeSeason: z.string(),
  productTypes: z.array(z.string()),
  hasAwProducts: z.boolean(),
  hasSsProducts: z.boolean(),
})

export type GetRecommendationFilterDataResponse = z.infer<typeof getRecommendationFilterDataSchema>

export async function getRecommendationFilterData(ShopifyProductIds: string[], locale = DEFAULT_LOCALE, draftMode = false): Promise<GetRecommendationFilterDataResponse> {
  const query = defineQuery(`{
    'products': *[
      _type == 'product' &&
      shopifyProductId[$locale] in $ShopifyProductIds
      && showInLocales[$locale] != false
    ] {
      ...*[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0] {
        productType,
        collectionSeason
      },
    },
    'activeSeason': ${GET_SANITY_ACTIVE_COLLECTION_SEASON_FRAGMENT()}
  } {
    activeSeason,
    'productTypes': array::unique(products[].productType),
    'hasAwProducts': 'aw' in products[].collectionSeason,
    'hasSsProducts': 'ss' in products[].collectionSeason
  }
  `)

  const params = {
    locale,
    ShopifyProductIds,
  }

  return await sanityFetch({ query, draftMode, params })
}

export const GetSurveysResponseSchema = z.object({
  title: z.string(),
})

type GetSurveysResponse = z.infer<typeof GetSurveysResponseSchema>

export const getSurveys = async (locale = DEFAULT_LOCALE, draftMode = true): Promise<GetSurveysResponse> => {
  const query = defineQuery(`
    *[_type == 'survey'][0] {
    ${coalesceQuery('title', locale)},
    }
  `)

  return await sanityFetch({ query, draftMode })
}
